import React, { Component } from 'react';
import { ProviderUpdate } from './ProviderUpdate';
import ReactGA from 'react-ga';


ReactGA.pageview(window.location.pathname + window.location.search);
export class ProviderSearch extends Component {
    static displayName = ProviderSearch.name;

    constructor(props) {
        super(props);
        this.state = {
            medicareCert: '',
            zip: '',
            covidplacementAvailable: false,
            numCovidbedsAvailable: 0,
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            lastUpdated: '',
            updateStatus: ''

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.providerUpdateElement = React.createRef();
        this.updateProvider = this.updateProvider.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'number' ? parseInt(value) : value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateProvider(e) {
        let testthis = this;
        fetch('api/providers', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },

            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function (response) {
            console.log(response)
            return response.json();
        }).then(function (data) {
            console.log(data)
            testthis.setState({
                lastUpdated: data.lastUpdated,
                updateStatus: "Record Updated Successfully"
            });
            
        });   

    }
    handleSubmit = (event) => {
        let testthis = this;
        //alert('A form was submitted: ' + this.state);
        event.preventDefault();
        fetch('api/providers', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },

            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function (response) {
            console.log(response)
            return response.json();
        }).then(function (data) {
            console.log(data)
            testthis.setState({
                covidplacementAvailable: data.covidplacementAvailable,
                numCovidbedsAvailable: data.numCovidbedsAvailable,
                contactName: data.contactName,
                contactPhone: data.contactPhone,
                contactEmail: data.contactEmail,
                lastUpdated: data.lastUpdated,
                updateStatus: "Record Found for Provider"
            });
        });
        
        
    }

    render() {
        const zip = this.state.zip;
        const medicareCert = this.state.medicareCert;
        //const covidplacementAvailable = this.state.covidplacementAvailable;

        return (
            <div>
                <table><tr>
                    <td>

                        <h2>Provider Available Beds</h2>
                        <p><strong>
                            Participation in this database is voluntary. Listing a bed does not guaranty admission for any specific individual and all admissions are subject to CMS and DIA regulations and guidance.
                            Please note that admission of a COVID-19 positive or recovering patient does not require a provider to enter outbreak status.
                            Providers should update the information as frequently as possible but no less than weekly during the Public Health Emergency.</strong></p>
                        <form onSubmit={this.handleSubmit}>
                            <p>Enter Provider/Medicare Cert # and Zip code to retrieve Provider Data</p>
                            <label>

                                Provider Number / Medicare Cert #:
          <input
                                    name="medicareCert"
                                    type="text"
                                    value={this.state.medicareCert}
                                    onChange={this.handleInputChange} />
                            </label>
                            <br />
                            <label>
                                Zip Code(Enter First Five Digits):
          <input
                                    name="zip"
                                    type="text"
                                    value={this.state.zip}
                                    onChange={this.handleInputChange} />
                            </label>
                            <br />

                            <input type="submit" value="Get Provider Data" />
                        </form>

                        <ProviderUpdate
                            zip={zip}
                            lastUpdated={this.state.lastUpdated}
                            medicareCert={medicareCert}
                            covidplacementAvailable={this.state.covidplacementAvailable}
                            numCovidbedsAvailable={this.state.numCovidbedsAvailable}
                            contactName={this.state.contactName}
                            contactPhone={this.state.contactPhone}
                            contactEmail={this.state.contactEmail}
                            onProviderChange={this.handleInputChange}
                            updateStatus={this.state.updateStatus}
                            updateProvider={this.updateProvider}
                        />
                    </td>
                    <td>
                        
                    </td>
                </tr></table>

            </div>
        );
    }
}
