import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Providers } from './components/Providers';
import { Counter } from './components/Counter';
import { ProviderUpdate } from './components/ProviderUpdate';
import { ProviderSearch } from './components/ProviderSearch';


import './custom.css'
ReactGA.initialize('UA-39814432-3');
ReactGA.pageview(window.location.pathname + window.location.search);
export default class App extends Component {

    static displayName = App.name;

    render() {
        return (
            
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/counter' component={Counter} />
                <Route path='/fetch-data' component={FetchData} />
                <div><Route path='/providers' component={Providers} /></div>
                <div><Route path='/providerupdate' component={ProviderUpdate} /></div>
                <div><Route path='/providersearch' component={ProviderSearch} /></div>
               
               

            </Layout>

        );
    }
}
