import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { useRef, useContext, useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

export class Providers extends Component {
    static displayName = Providers.name;

    constructor(props) {
        super(props);
        this.state = { providers: [], loading: true, providerNum: '', zipcode: '', showall: true , sort: 1 };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleCitySort = this.handleCitySort.bind(this);
        this.handleCountySort = this.handleCountySort.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        //this.populateProviderData();
    }

    handleCitySort(e) { 
        e.preventDefault();
        if (this.state.sort === 1)
        {
            this.setState({sort: 2});
            this.populateProviderData(2)
        }
        else
        {
            this.setState({ sort: 1 });
            this.populateProviderData(1)
        }
    }

    handleCountySort(e) {
        e.preventDefault();
        if (this.state.sort === 3) {
            this.setState({ sort: 4 });
            this.populateProviderData(4)
        }
        else {
            this.setState({ sort: 3 });
            this.populateProviderData(3)
        }
    }

    handleSearchClick() {
        this.populateProviderData(1);
    }
    componentDidMount() {
        //this.populateProviderData();
    }

    componentDidUpdate() {
        //this.populateProviderData();
    }

    renderProvidersTable(providers) {
        return (
            <div>
                <p><strong>Bed availability- While every effort is made to keep this tool updated, Hospital Discharge Planners should contact the provider directly to determine the correct number of beds available for 
                    COVID positive patients. Hospital Discharge Planners seeking to transfer patients to SNFs should understand that admissions decisions are subject to State and Federal regulations and guidance. 
                  Provider participation in this tool is voluntary. Listing a bed on this tool does not provide a guaranty of admission for any specific individual as each potential resident’s care needs/ level of care will need to be considered.
            </strong></p>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th><a href="#" onClick={this.handleCitySort}>City</a></th>
                            <th>Zip</th>
                            <th><a href="#" onClick={this.handleCountySort}>County</a></th>
                            <th>Last Updated</th>
                            <th>Beds Available</th>
                            <th># Beds Available</th>
                            <th>Contact Name</th>
                            <th>Contact Phone</th>
                            <th>Contact Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {providers.map(provider =>
                            <tr>
                                <td>{provider.providerName}</td>
                                <td>{provider.city}</td>
                                <td>{provider.zip}</td>
                                <td>{provider.county}</td>
                                <td><Moment format="MMM D hh:mm:ss">{provider.lastUpdated}</Moment></td>
                                <td>{String(provider.covidplacementAvailable ? 'Yes' : 'No')}</td>
                                <td>{provider.numCovidbedsAvailable}</td>
                                <td>{provider.contactName}</td>
                                <td>{provider.contactPhone}</td>
                                <td>{provider.contactEmail}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderProvidersTable(this.state.providers);


        return (
            <div>
                <p><strong>Enter your facility ID and Zip code to search providers with available beds
            </strong></p>
                <label>
                    Provider ID:
          <input
                        name="providerNum"
                        type="text"
                        value={this.state.providerNum}
                        onChange={this.handleInputChange}
                    />
                </label>

                <label>
                    Zip Code:
          <input
                        name="zipcode"
                        type="text"
                        value={this.state.zipcode}
                        onChange={this.handleInputChange}
                    />
                </label>
                <label> Only Show Available Beds:
                <input
                        name="showall"
                        type="checkbox"
                        checked={this.state.showall}
                        onChange={this.handleInputChange}
                    />
                </label>
                <button onClick={this.handleSearchClick}>
                    Search
                </button>
                <h1 id="tabelLabel" >Providers</h1>
                <p>COVID Beds Tracking</p>
                {contents}
            </div>
        );
    }

    async populateProviderData(sort) {
        const response = await fetch(`api/providers?providerid=${encodeURIComponent(this.state.providerNum)}&zipcode=${encodeURIComponent(this.state.zipcode)}&showall=${encodeURIComponent(this.state.showall)}&sort=${encodeURIComponent(sort)}`);
        //const providerid = this.state.providerNum;
        //const zipcode = this.state.zipcode;
        //const showall = this.state.showall;
        //const response = await fetch('api/providers?providerid=12345&zipcode=12345&showall=false');
        const data = await response.json();
        if (response.ok) {
            this.setState({ providers: data, loading: false });
        }
        else { this.setState({ providers: data, loading: true });}
    }
}
