import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';

export class ProviderUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        this.props.onProviderChange(e);
        //const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;

        //this.setState({
        //    [name]: value
        //});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.updateProvider(e);
        //alert('A form was submitted: ' + this.state);
        //let testthis = this;
       
        //fetch('api/providers', {
        //    method: 'PUT',
        //    headers: {
        //        "Content-Type": "application/json",
        //    },

            // We convert the React state to JSON and send it as the POST body
        //    body: JSON.stringify(this.props)
        //}).then(function (response) {
        //    console.log(response)
        //    return response.json();
        //}).then(function (data) {
        //    console.log(data)
        //    var lastupdated = data.lastUpdated;
        //});    
    }

    render() {
        const contactName = this.props.contactName;
        return (
            <div>
            <h2>Provider Beds Data</h2>
                <form onSubmit={this.handleSubmit}>
                    <label>
                       
                        Last Updated:  {Date.parse(this.props.lastUpdated) ? <Moment format="MMM D hh:mm:ss">{this.props.lastUpdated}</Moment> : "" }
                </label><br />
                    <p className="updateStatus"> {this.props.updateStatus}</p>
              
                <label>
                    Check this box if you have COVID Beds Available: 
          <input
                        name="covidplacementAvailable"
                        type="checkbox"
                        checked={this.props.covidplacementAvailable}
                        onChange={this.handleInputChange}
                        />
                </label>
                <br />
                <label>
                    Number of Covid Beds Available:
          <input
                        name="numCovidbedsAvailable"
                        type="number"
                        value={this.props.numCovidbedsAvailable}
                        onChange={this.handleInputChange}
                        />
                </label>
                <br />
                <label>
                    Contact Name:
          <input
                        name="contactName"
                        type="text"
                        value={contactName}
                        onChange={this.handleInputChange}
                        />
                </label>
                <br />
                <label>
                    Contact Phone:
          <input
                        name="contactPhone"
                        type="tel"
                        value={this.props.contactPhone}
                        onChange={this.handleInputChange}
                        />
                </label>
                <br />
                <label>
                    Contact Email:
          <input
                        name="contactEmail"
                        type="email"
                        value={this.props.contactEmail}
                        onChange={this.handleInputChange}
                        />
                </label>
                <br />
                <input type="submit" value="Update" />
            </form>
            </div>
        );
    }
}